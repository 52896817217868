@import "../../utils/variables";
@import "../../utils/mixins";

.bottom-navbar {
  position: fixed;
  // max-width: 480px;
  height: 66px;
  background-color: rgba(255, 255, 255, 0.01);
  z-index: 500;
  transition: transform 0.4s ease;
  display: none;
  width: 101%;
  left: 50%;
  transform: translate(-50%, 150%);
  //backdrop-filter: blur(4px);
  bottom: 0;
  color: rgba(45, 45, 45, 0.6);

  &::before,
  &::after {
    content: '';
    width: 25%;
    height: 66px;
    background-repeat: repeat-x;
    position: absolute;
    bottom: 0;
    background-image: url("/src/images/bottom-navbar-light-bg.svg");
    transform: scaleX(-1);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    z-index: -1;
  }

  .embla {
    margin-top: 11px;
    position: relative;
    z-index: 1;
  }

  .mold {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    mix-blend-mode: hue;
    z-index: 1;
  }

  @include sm() {
    display: block;
  }

  &[data-isDark] {

    &::before,
    &::after {
      background-image: url("/src/images/bottom-navbar-dark-bg.svg");
    }

    color: rgba(255, 255, 255, 0.6);

    a {
      color: rgba(255, 255, 255, 0.6);
    }

    .bottom-navbar__item-image {
      svg {
        path {
          fill: $white;
        }
      }
    }

    .arrow-next {
      background-color: transparent;
      border: none;

      svg {
        path {
          stroke: $white;
        }
      }
    }

    .mold {
      linearGradient {
        stop {
          &:nth-child(1) {
            stop-color: #435F89;
          }

          &:nth-child(2) {
            stop-color: #2E4567;
          }

          &:nth-child(3) {
            stop-color: #293B58;
          }

          &:nth-child(4) {
            stop-color: #1F3049;
          }

          &:nth-child(5) {
            stop-color: #182840;
          }
        }
      }
    }

    li.is-active {

      .bottom-navbar__item-image,
      svg {
        img {
          filter: none;
        }
      }

    }

  }

  &__item-image {

    img,
    svg {
      opacity: 0.6;
      // transition: 0.2s ease-in-out;
      // transition-property: opacity;
    }
  }

  &_opened {
    transform: translate(-50%, 1px);
    content-visibility: visible;

    .bottom-navbar__close {
      transform: translate(-14px, -88px);
    }
  }

  &_opened-with-cookies {
    transform: translate(-50%, -43px);

    .bottom-navbar__close {
      // bottom: calc(67px + 43px);
      transform: translate(-14px, -88px);
    }
  }

  &__item {
    height: 43px;
    max-height: 43px;
    @include center-flex();
    flex-direction: column;
    // min-width: 50px;
    // max-width: 50px;
    border: none;

    a {
      @include center-flex();
      flex-direction: column;
      @include size(100%, 100%);
      color: rgba(45, 45, 45, 0.6)
    }

    span {
      @include font(11px, 15px, 400);
      display: block;
      white-space: nowrap;
      margin-top: auto;
    }

    &-image {
      display: block;
      transition: 0.3s ease-in-out;
      transition-property: top, width, height, min-height;
      border-radius: 30px;
      position: relative;
      top: 0px;
      background-color: transparent;

      .svg-telegram {
        position: relative;
        left: 0.5px;
      }
    }
  }

  li.is-active {
    .bottom-navbar__item-image {
      @include size(48px, 48px);
      min-height: 48px;
      border-radius: 30px;
      position: absolute;
      background-color: $main;
      @include center-flex();

      img,
      svg {
        @include size(34px, 34px);
        margin-bottom: 0;
        opacity: 1;
      }

      img {
        filter: invert(1) brightness(2);
      }

      svg {
        path {
          fill: $white;
          stroke: $white;
        }
      }
    }

    span {
      color: #FF7534;
      transform: translateY(2px);
      font-weight: 600;
    }
  }

  &__close {
    filter: drop-shadow(1px 1px 18px rgba(0, 0, 0, 0.16));
    border-radius: 50px;
    position: fixed;
    @include size(18px, 18px);
    @include center-flex();
    right: 0;
    bottom: 0;
    transform: translate(60px, -88px);
    transition: transform 0.2s ease-in-out;
  }
}

.bottom-navbar-wrapper {
  opacity: 0;
  position: relative;
  left: -100vw;

  &_opened {
    opacity: 1;
    left: 0;
  }
}

.bottom-navbar .splide__track--nav>.splide__list>.splide__slide.is-active {
  border: none;
}

.bottom-navbar .splide__track--nav>.splide__list>.splide__slide {
  transition: all 0.2s ease-in-out;
  border: none;
}

.bottom-navbar .splide__track {
  overflow: visible;
}

.bottom-navbar .arrow-next {
  background-color: transparent;
  @include size(20px, 58px);
  background-color: transparent;
  border-radius: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  z-index: 1;

  svg {
    @include size(6px, 12px);
    fill: transparent;
  }
}

.bottom-navbar .arrow-next:hover:not(:disabled) {
  opacity: 1;
}

.bottom-navbar-btn {
  @include size(24px, 24px);
  border-radius: 50px;
  @include center-flex();
  background-color: $main;
  position: fixed;
  right: 10px;
  bottom: 85px;
  z-index: 1000;
  transition: 0.4s ease;
  transition-property: background-color, right, bottom, box-shadow;

  &_hidden {
    right: -50px;
  }

  &_cookie {
    bottom: calc(12px + 43px);
  }

  display: none;

  @include sm() {
    display: flex;
  }
}

.embla {
  max-width: 100%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 30px;
  --slide-size: 80px;
}

.embla__viewport {}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}


.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}