@import "../../utils/variables";
@import "../../utils/mixins";

.footer {
  padding-bottom: 32px;
  border-top: solid 1px rgba(210, 210, 210, 0.4);
  background-color: #F4F4F4;

  @include sm {
    padding-bottom: 92px;
  }

  &__firstBlock {
    padding: 32px 0;
    background-color: $white;

    @include sm {
      padding-top: 16px;
      padding-bottom: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px 32px;

    @include sm {
      grid-template-columns: 1fr 1fr;
      gap: 24px 0;
    }

    &-col {
      @include sm {
        border-bottom: solid 1px rgba(210, 210, 210, 0.4);
        padding-bottom: 24px;
        padding-right: 24px;
        order: 1;

        &:nth-child(2) {
          order: 0;
        }


        &:nth-child(2n) {
          padding-right: 0;
        }

        &:last-child,
        &:nth-last-child(2) {
          border-bottom: none;
        }
      }
    }
  }

  &__secondBlock {
    padding-top: 24px;

    .footer__grid-col {
      @include sm {
        border-bottom: solid 1px rgba(210, 210, 210, 0.4);
      }

      &:last-child {
        grid-column: span 2;

        @include sm {
          border-bottom: none;
          padding-right: 0;
          padding-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          color: $text-color;
          @include font(16px, 22px, 500);

          img,
          svg {
            margin-right: 8px;
          }
        }

        .footer__subitems {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px 32px;

          @include sm {
            gap: 12px 24px;
          }
        }
      }
    }
  }

  &__title {
    @include font(16px, 22px, 800);
    color: $text-color;
    margin-bottom: 12px;
    display: block;

    @include sm {
      @include font(14px, 19px);
    }
  }

  &__subitems {
    display: flex;
    flex-direction: column;

    a {
      display: block;
      color: $text-color;
      @include font(16px, 22px, 500);
      margin-bottom: 8px;

      @include sm {
        @include font(14px, 19px);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__socials {
    a {
      margin-bottom: 0;
    }
  }

  &__policy {
    border-top: solid 1px rgba(210, 210, 210, 0.4);
    width: 100%;
    padding-top: 32px;
    display: block;
    text-align: center;
    @include font(14px, 19px, 500);
    margin: 0 auto;
    margin-top: 32px;

    @include sm {
      text-align: left;
      margin-top: 24px;
      padding-top: 24px;
    }
  }
}